<template>
    <!-- Page header -->
    <div v-bind="$attrs" class="max-w-7xl mx-auto  md:items-center 3xl:max-w-screen-2xl lg:px-1 4xl:max-w-none">
	    <div class="md:grid md:grid-cols-3 shadow relative divide-x">
		    <div class="hidden lg:block flex items-center col-span-2 grid rounded px-5 py-5 h-full z-10">
			    <div class="flex items-center col-span-2">
				    <h1 class="text-2xl font-bold">
					    {{ compactorMonitor.monitoredService?.location.name ?? compactorMonitor.id }}
				    </h1>
				    <div class="ml-4">
					    {{ compactorMonitor.monitoredService?.location.address.full_address }}
				    </div>
			    </div>
			    <div v-if="compactorMonitor.monitoredService.label" class="cols-span-2"> {{ compactorMonitor.monitoredService.label }}</div>
			    <div class="flex items-center gap-x-4 col-span-2 mt-2">
				    <inertia-link :href="$route('services.show', compactorMonitor.monitoredService.id)" class="link font-bold whitespace-nowrap">{{ compactorMonitor.monitoredService.id }}</inertia-link>
				    <div class="text-xs">{{ compactorMonitor.monitoredService.description }}</div>
			    </div>
		    </div>
		    <div class="block lg:hidden items-center col-span-3 grid-cols-4 rounded px-4 py-5 h-full relative">
			    <div class="flex items-center col-span-4">
				    <h1 class="text-2xl font-bold">
					    {{ compactorMonitor.monitoredService?.location.name ?? compactorMonitor.id }}
				    </h1>
			    </div>
			    <div class="col-span-4">
				    {{ compactorMonitor.monitoredService?.location.address.full_address }}
			    </div>
			    <div class="flex items-center gap-x-2 mt-4">
				    <inertia-link target="_blank" :href="$route('services.show', compactorMonitor.monitoredService.id)" class="link font-bold">{{ compactorMonitor.monitoredService.id }}</inertia-link>
			    </div>
			    <div v-if="compactorMonitor.monitoredService.label" class="mt-4 col-span-3 text-right">
				    {{ compactorMonitor.monitoredService.label }}
			    </div>
			    <div class="flex items-center gap-x-2 col-span-4 mt-2">
				    <div class="text-xs">{{ compactorMonitor.monitoredService.description }}</div>
			    </div>
			    <div class="hidden md:block lg:hidden absolute right-5 top-5 col-span-2 lg:col-span-1">
				    <div class="flex">
					    <div class="flex items-center gap-x-2" :content="$filters.capitalize(compactorMonitor.connectivity_status)" v-tippy>
						    <div v-if="!signaling" :class="[statuses[compactorMonitor.connectivity_status], 'flex-none rounded-full p-1']">
							    <div class="h-3 w-3 rounded-full bg-current"/>
						    </div>
						    <div v-else class="flex-none rounded-full p-1">
							    <div class="rainbow-transition h-3 w-3 rounded-full bg-current"/>
						    </div>
					    </div>
					    <div class="items-center">
					    </div>
					    <button class="justify-center" @click="signalCompactorMonitor" :content="signaling ? 'Signaling...' : 'Click to signal monitor'" v-tippy>
						    <icon name="pioneer" class="h-10 w-10"/>
					    </button>
				    </div>
			    </div>
		    </div>
		    <div class="hidden lg:block h-full">
			    <div class="grid grid-cols-3 p-3 h-full items-center ">
				    <div class="col-span-1 lg:col-span-1">
					    <div class="flex">
						    <div class="flex items-center gap-x-2" :content="$filters.capitalize(compactorMonitor.connectivity_status)" v-tippy>
							    <div v-if="!signaling" :class="[statuses[compactorMonitor.connectivity_status], 'flex-none rounded-full p-1']">
								    <div class="h-3 w-3 rounded-full bg-current"/>
							    </div>
							    <div v-else class="flex-none rounded-full p-1">
								    <div class="rainbow-transition h-3 w-3 rounded-full bg-current"/>
							    </div>
						    </div>
						    <div class="items-center">
						    </div>
						    <button class="justify-center" @click="signalCompactorMonitor" :content="signaling ? 'Signaling...' : 'Click to signal monitor'" v-tippy>
							    <icon name="pioneer" class="h-10 w-10"/>
						    </button>
					    </div>
				    </div>

				    <div>
					    <dt class="text-sm font-medium text-gray-500">Monitor ID</dt>
					    <dd class="mt-1 text-sm text-gray-900">
						    {{ compactorMonitor.id }}
					    </dd>
				    </div>
				    <div>
					    <dt class="text-sm font-medium text-gray-500">Install Date</dt>
					    <dd class="mt-1 text-sm text-gray-900">
						    {{ $filters.format_date(compactorMonitor.monitorConfiguration?.first_run_detected_at) }}
					    </dd>
				    </div>
			    </div>
		    </div>
	    </div>
	    <div v-if="needsAttention" class="w-auto col-span-3 bg-d-yellow-100 flex items-center p-4 mx-2 md:mx-3 lg:mx-4 rounded z-10">
		    <icon name="exclamation-triangle" class="text-rose-400 fill-current h-8 w-8 inline"/>
		    <span class="ml-4 text-lg ">No runs detected within 2 business days!</span>
	    </div>
    </div>
	<div class="max-w-7xl mx-auto sm:px-6 md:items-center md:grid md:grid-cols-3 3xl:max-w-screen-2xl lg:px-1 4xl:max-w-none">
		
	</div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from "../../../Shared/LoadingButton.vue";
    import SubNav from "./SubNav.vue";
    import {differenceInBusinessDays} from "date-fns";

    export default {
    components: {
	    SubNav,
        LoadingButton,
        Icon,
    },

    props: {
        errors: {
            type: Object,
            default: () => ({})
        },

        compactorMonitor: {
            type: Object,
            required: true
        },
    },

    data() {
        return {
            mounted: false,
            signaling: false,
	        needsAttention: false,
	        statuses: {
		        'online': 'animate-pulse text-green-400 bg-green-400/20',
		        'offline': 'animate-pulse text-rose-400 bg-rose-400/20',
		        'inactive': 'text-gray-400 bg-gray-400/20',
	        },
        }
    },

    mounted() {
		this.getNeedsAttention();
        Echo.join(`compactor_monitors.${this.compactorMonitor.id}`)
            .listen('.App\\Events\\CompactorMonitor\\SignalCompactorMonitorJobStarted', (e) => {
                this.signaling = true;
            })
            .listen('.App\\Events\\CompactorMonitor\\SignalCompactorMonitorJobFinished', (e) => {
                this.signaling = false;
                this.$toast.success('Finished signaling device.');
            });

        this.mounted = true;
    },

    unmounted() {
        Echo.leave(`compactor_monitors.${this.compactorMonitor.id}`);
    },

    methods: {
		getNeedsAttention() {
			if (this.compactorMonitor.monitorConfiguration.latestRun) {
				const lastRun = new Date(this.compactorMonitor.monitorConfiguration.latestRun.run_start);
				const diffDays = differenceInBusinessDays(new Date(), lastRun);
				if (diffDays >= 2) {
					this.needsAttention = true;
				}
			}
		},
        signalCompactorMonitor() {
            this.signaling = true;
            this.$inertia.post(this.$route('compactor-monitors.signal', this.compactorMonitor.id), {},{
                preserveState: true,
                preserveScroll: true,
            });
        },
    },
}
</script>