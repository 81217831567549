<template>
    <Head title="Create Email Template" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.index')" class="breadcrumb-link">Tenant Settings</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('tenant-settings.email-templates.index')" class="breadcrumb-link">Email Templates</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Create</span>
        </nav>
    </Teleport>

    <div class="container mx-auto">
        <div v-if="externalEmailDomainVerificationStatus === 'Verified'" class="bg-blue-100 rounded shadow-md p-4 my-4">
            <p>Please ensure From Email ends in {{ fromEmailShouldEndWith }} to ensure email deliverability.</p>
        </div>
        <div v-else-if="externalEmailDomainVerificationStatus === 'Partial - Missing MX Records'" class="bg-yellow-100 rounded shadow-md p-4 my-4">
            <p>Your external email domain is partially verified, but is missing recommended MX records.</p>
            <p>Please add the MX records listed on the <inertia-link class="link" :href="route('tenant-settings.external-email-settings.edit')">external email settings</inertia-link> page to your DNS to ensure email deliverability.</p>
            <p>Please end the "From Email" address with {{ fromEmailShouldEndWith }}.</p>
        </div>
        <div v-else class="bg-yellow-100 rounded shadow-md p-4 my-4">
            <p>Your external email domain is not verified. Please update your <inertia-link class="link" :href="route('tenant-settings.external-email-settings.edit')">external email settings</inertia-link> to ensure email deliverability.</p>
        </div>

        <div class="my-4">
            <form id="edit-email-template" class="w-full">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <select-input v-model="form.type" label="Template Type" :errors="errors['type']" markAsRequired>
                        <option v-for="type in availableTypes" :value="type.type" :key="type.type">{{ type.type }}</option>
                    </select-input>

                    <text-input v-model="form.from_email" label="From Email" :errors="errors.from_email" mark-as-required/>
                    <text-input v-model="form.from_name" label="From Name" :errors="errors.from_name" mark-as-required/>
                    <text-input v-model="form.cc_recipients" label="CC Recipients" :errors="errors.cc_recipients" />
                    <text-input v-model="form.bcc_recipients" label="BCC Recipients" :errors="errors.bcc_recipients" />

                    <text-input-with-variables v-model="form.subject" :variables="templateVariables" class="md:col-span-2 lg:col-span-3" label="Subject" :errors="errors.subject" mark-as-required/>

                    <div class="md:col-span-2 lg:col-span-3">
                        <quill-editor v-model="form.body" label="Body" :key="form.type" :errors="errors.body" :variables="templateVariables" mark-as-required/>
                    </div>
                </div>

                <div class="mt-6">
                    <loading-button class="btn btn-blue" :loading="state === 'saving'" :disabled="state !== 'passive'" @click.prevent="storeEmailTemplate">
                        Save
                    </loading-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { ref, reactive, inject, onMounted, watch} from 'vue';

// Components
import { Head } from '@inertiajs/vue3';
import Icon from '@/Shared/Icon.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import QuillEditor from "@/Shared/QuillEditor.vue";
import SelectInput from '@/Shared/SelectInput.vue';
import TextInput from '@/Shared/TextInput.vue';
import TextInputWithVariables from '@/Shared/TextInputWithVariables.vue';

// Inject
const route = inject('route');
const state = ref('passive');
const templateVariables = ref([]);

// Props
const props = defineProps({
    emailTemplate: {
        type: Object,
        required: true
    },

    availableTypes: {
        type: Object,
        required: true
    },

    externalEmailDomainVerificationStatus: {
        type: String,
        required: true
    },

    fromEmailShouldEndWith: {
        type: String,
    },

    errors: {
        type: Object,
        default: () => ({})
    },
});

// State
const form = reactive({
    ...props.emailTemplate
});
const mounted = ref(false);

// Mount
onMounted(() => {
    mounted.value = true;
});

// Methods
function storeEmailTemplate() {
    if (state.value !== 'passive') {
        return;
    }

    state.value = 'saving';

    router.post(route('tenant-settings.email-templates.store'), form, {
        preserveScroll: true,
        onFinish() {
            state.value = 'passive';
        }
    });
}

/**
 * Watchers
 */
watch(() => form.type, (newValue) => {
    if (!newValue) {
        form.subject = null;
        form.body = null;
        form.from_email = null;
        form.from_name = null;
        return;
    }

    let template = props.availableTypes[newValue];

    form.subject = template.subject;
    form.body = template.body;
    form.from_email = template.from_email;
    form.from_name = template.from_name;

    templateVariables.value = template.variables;
});
</script>